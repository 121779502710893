/* eslint-disable vue/no-v-html */
<template>
  <div class="domain-detail-whois">
    <page-block :title="$t('title')">
      <main-card>
        <div ref="whois" class="domain-detail-whois__content standart-text" />
      </main-card>
    </page-block>
  </div>
</template>

<script>
import PageBlock from '@/components/PageBlock/PageBlock.vue';
import MainCard from '@/components/MainCard/MainCard';
import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import { DomainTariff } from '@/models/BillMgr/DomainTariff';
export default {
  name: 'DomainDetailWhois',
  components: { PageBlock, MainCard },
  mixins: [billMgrToolActions],
  props: {
    tariff: {
      type: DomainTariff,
      required: true,
      validator: obj => obj instanceof DomainTariff,
    },
  },
  computed: {
    tools() {
      return this.$store.state.moduleDomains.moduleDomainsDomains.tools;
    },
    toolWhois() {
      if (!this.tools || !this.tools.whois || !this.tools.whois.isEnable(this.tariff)) return null;
      return this.tools.whois;
    },
  },
  watch: {
    toolWhois(tool) {
      if (tool && tool.isEnable(this.tariff)) this.fetch();
    },
  },
  created() {
    if (this.toolWhois && this.toolWhois.isEnable(this.tariff)) this.fetch();
  },
  methods: {
    fetch(id = this.tariff.id) {
      const params = {
        elid: id,
        func: this.toolWhois.func,
      };
      this.fetchBillMgrAction(params).then(data => {
        this.fillWhois(data.model.whois_data);
      });
    },
    fillWhois(str) {
      let res = str.replace(/$#|%(.+)\n/g, '');
      this.$refs.whois.innerHTML = res.trim();
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Whois"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.domain-detail-whois {
  &__content {
    white-space: break-spaces;
  }
}
</style>
